import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col} from "reactstrap"
import ContactImg from "../components/contactimg"
import styled from "styled-components"
import {Link} from "gatsby"

const ThankYouPage = () => (
  <Layout>
    <SEO title="Thank you so much!" />
    <Container fluid={true}>
      <Row>
        <Col lg="6" className="dual-left p-0">
          <ImageBorder>
            <ContactImg />
          </ImageBorder>
        </Col>
        <Col lg="6" className="about-content px-sm-5 py-4">
          <div>
            <h1 className="text-center">Thank you.</h1>
            <hr />
            <p>
              <b>You've subscribed.</b>
            </p>
            <p>We appreciate you taking the time to subscribe today.</p>
            <p>
              We'll contact you from time to time about interesting word and
              custom facts we discover (you can unsubscribe anytime by clicking
              the unsubscribe link in our emails).
            </p>
            <p>
              <b>Chat soon.</b>
            </p>
            <p>
		    <Link to="/blog/">Click here</Link> to return to our Spark Files.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ThankYouPage

const ImageBorder = styled.div`
  border-right: 4px solid #f56545;
  @media (max-width: 980px) {
    border-right: none;
    border-bottom: 4px solid #f56545;
  }
`
